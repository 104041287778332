<template>
  <div>
    <v-card :style="$vuetify.theme.dark ? '' : 'background-color: #f7f7f7'" height="100vh">
      <v-container fluid>
        <v-row no-gutters v-if="fieldsLoading">
          <v-col v-for="item in 12" :key="item" cols="4">
            <v-sheet class="my-2 px-2">
              <v-skeleton-loader class="mx-auto" type="image" height="75"></v-skeleton-loader>
            </v-sheet>
          </v-col>
        </v-row>
        <v-row v-else>
          <v-col cols="12" class="pa-2 pt-4">
            <v-card flat>
              <v-btn small icon dark @click="$emit('hideMoreModules')" class="ma-2 mb-0">
                <v-icon :color="systemDetails.themecolor"> mdi-arrow-left </v-icon>
              </v-btn>
              <v-container fluid>
                <v-row v-for="(item, listRowIndex) in commonList" :key="listRowIndex">
                  <v-col cols="4" v-for="(navItem, navItemIndex) in item.list" class="pa-0" :key="`${navItem._id}_module`"
                  :class="item.id !== 'navigationItems' && hasNextRow(navItemIndex, item.list) ? 'grid-border-bottom' : ''">
                    <v-card tile flat :to="navItem.to" :class="[isNotendItem(navItemIndex) ? 'grid-border-right' : '', 'pa-1']">
                      <div class="text-center pt-2">
                        <v-avatar>
                          <v-icon :color="$vuetify.theme.dark ? '' : systemDetails && systemDetails.themecolor ? systemDetails.themecolor : 'primary'" size="30">
                            {{ navItem.icon ? navItem.icon : 'mdi-cube' }}
                          </v-icon>
                        </v-avatar>
                      </div>
                      <v-card-text class="text-center px-2 pt-0 pb-2 font-weight-medium">
                        {{ navItem.isStatic ? $t(navItem.name) : $t(navItem.plural_label) }}
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  data () {
    return {
      customModules: [],
      fieldsLoading: false
    }
  },
  computed: {
    ...mapGetters(['listOfModules', 'userDetails', 'systemDetails']),
    commonList () {
      return [
        { id: 'customModules', list: this.customModules }
      ]
    }
  },
  beforeMount () {
    this.fieldsLoading = true
    this.$store.dispatch('getModuleList')
      .then((data) => {
        // this.customModules = data.filter((x, index) => x.isactive && index >= 8)
        this.customModules = data.filter((moduleItem) => moduleItem.isactive && !moduleItem.showonlyassubmodule).filter((moduleItem, index) => index >= 8)
        if (this.customModules.length === 0) this.$router.push('/dashboard')
      }).finally(() => {
        this.fieldsLoading = false
      })
  },
  methods: {
    isNotendItem (index) {
      // check if has next item
      if (this.customModules.length === 1) return false
      var item = index + 1
      var endPositions = [3, 6, 9, 12, 15]
      return !endPositions.includes(item)
    },
    hasNextRow (index, list) {
      var item = index + 2
      return item < list.length
    }
  }
}
</script>
<style>
/* .moreModules {
  height: calc(var(--vh, 1vh) * 100 - 56px);
} */
.grid-border-bottom {
  border-bottom: 0.85px solid #19191912 !important;
}
.grid-border-right {
  border-right: 0.85px solid #19191912 !important;
}
.custom-margin {
  margin-top: 28%;
}
.custom_bg_color {
  background: #1976d2 !important;
}
</style>
